import { ACTIONS } from "../constants"


const initialState = { 
  periode : [],
  ordersPeriode : [],
  ordersPeriodeValue : [],
  ordersReturnValue : [],
  feedbackValue : [],
  panierValue : [],
  visit : [],
  totalVisits : [],
  uniqueVisitor : [] ,
  convertionRate : [],
  abondonementRate : [],
  chouckout : [],
  orderReturnReason : [] ,
  orderFeedbackSubject : [],
  totalSize: 0,
  isFetching: false, 
  isLoading: false,
  hasMore: true, 
  error: null
}


export default (state = initialState , action) => {
  const { payload, type } = action
  switch (type) {

    case ACTIONS.GET_STATUSFREQUENCY_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.GET_STATUSFREQUENCY_SUCCEDED : {
      return { ...state, periode: payload, isFetching: false, error: null }
    }
    case ACTIONS.GET_STATUSFREQUENCY_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }


    case ACTIONS.GET_ORDERREASON_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.GET_ORDERREASON_SUCCEDED : {
      return { ...state, orderReturnReason: payload, isFetching: false, error: null }
    }
    case ACTIONS.GET_ORDERREASON_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.GET_FEEDBACKSUBJECT_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.GET_FEEDBACKSUBJECT_SUCCEDED : {
      return { ...state, orderFeedbackSubject: payload, isFetching: false, error: null }
    }
    case ACTIONS.GET_FEEDBACKSUBJECT_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }
    


    case ACTIONS.GET_STATUSORDERS_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.GET_STATUSORDERS_SUCCEDED : {
      return { ...state, ordersPeriode: payload, isFetching: false, error: null }
    }
    case ACTIONS.GET_STATUSORDERS_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }
    case ACTIONS.GET_STATUSORDERSVALUE_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.GET_STATUSORDERSVALUE_SUCCEDED : {
      return { ...state, ordersPeriodeValue: payload, isFetching: false, error: null }
    }
    case ACTIONS.GET_STATUSORDERSVALUE_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.GET_STATUSORDERSRETURN_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.GET_STATUSORDERSRETURN_SUCCEDED : {
      return { ...state, ordersReturnValue: payload, isFetching: false, error: null }
    }
    case ACTIONS.GET_STATUSORDERSRETURN_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }


    case ACTIONS.GET_STATUSFEEDBACK_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.GET_STATUSFEEDBACK_SUCCEDED : {
      return { ...state, feedbackValue: payload, isFetching: false, error: null }
    }
    case ACTIONS.GET_STATUSFEEDBACK_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }

    //VISISTS


    case ACTIONS.VISITS_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.VISITS_SUCCEDED : {
      return { ...state, visit: payload, isFetching: false, error: null }
    }
    case ACTIONS.VISITS_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.UNIQUEVISITOR_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.UNIQUEVISITOR_SUCCEDED : {
      return { ...state, uniqueVisitor: payload, isFetching: false, error: null }
    }
    case ACTIONS.UNIQUEVISITOR_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }


    case ACTIONS.CONVERTIONRATE_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.CONVERTIONRATE_SUCCEDED : {
      return { ...state, convertionRate: payload, isFetching: false, error: null }
    }
    case ACTIONS.CONVERTIONRATE_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }


    case ACTIONS.ABONDONEMENT_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.ABONDONEMENT_SUCCEDED : {
      return { ...state, abondonementRate: payload, isFetching: false, error: null }
    }
    case ACTIONS.ABONDONEMENT_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.TOTALVISITS_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.TOTALVISITS_SUCCEDED : {
      return { ...state, totalVisits: payload, isFetching: false, error: null }
    }
    case ACTIONS.TOTALVISITS_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }
    

    
    
//CHECKOUTS

    case ACTIONS.CHECKOUTS_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.CHECKOUTS_SUCCEDED : {
      return { ...state, chouckout: payload, isFetching: false, error: null }
    }
    case ACTIONS.CHECKOUTS_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }
   
    // 

    case ACTIONS.GET_STATUSPANIER_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.GET_STATUSPANIER_SUCCEDED : {
      return { ...state, panierValue: payload, isFetching: false, error: null }
    }
    case ACTIONS.GET_STATUSPANIER_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }
    

    default: {
      return state
    }
  }
}
