// color
export const FETCH_COLORS_INIT = "color@FETCH_COLORS_INIT"
export const FETCH_COLORS_SUCCEDED = "color@FETCH_COLORS_SUCCEDED"
export const FETCH_COLORS_FAILED = "color@FETCH_COLORS_FAILED"

export const CLEAR_COLOR = "color@CLEAR_COLOR"

export const CREATE_COLOR_INIT = "color@CREATE_COLOR_INIT"
export const CREATE_COLOR_SUCCEDED = "color@CREATE_COLOR_SUCCEDED"
export const CREATE_COLOR_FAILED = "color@CREATE_COLOR_FAILED"

export const FETCH_COLOR_INIT = "color@FETCH_COLOR_INIT"
export const FETCH_COLOR_SUCCEDED = "color@FETCH_COLOR_SUCCEDED"
export const FETCH_COLOR_FAILED = "color@FETCH_COLOR_FAILED"

export const EDIT_COLOR_INIT = "color@EDIT_COLOR_INIT"
export const EDIT_COLOR_SUCCEDED = "color@EDIT_COLOR_SUCCEDED"
export const EDIT_COLOR_FAILED = "color@EDIT_COLOR_FAILED"

export const DELETE_COLOR_INIT = "color@DELETE_COLOR_INIT"
export const DELETE_COLOR_SUCCEDED = "color@DELETE_COLOR_SUCCEDED"
export const DELETE_COLOR_FAILED = "color@DELETE_COLOR_FAILED"
