// packType
export const FETCH_PACK_TYPES_INIT = "packType@FETCH_PACK_TYPES_INIT"
export const FETCH_PACK_TYPES_SUCCEDED = "packType@FETCH_PACK_TYPES_SUCCEDED"
export const FETCH_PACK_TYPES_FAILED = "packType@FETCH_PACK_TYPES_FAILED"

export const CLEAR_PACK_TYPE = "packType@CLEAR_PACK_TYPE"

export const CREATE_PACK_TYPE_INIT = "packType@CREATE_PACK_TYPE_INIT"
export const CREATE_PACK_TYPE_SUCCEDED = "packType@CREATE_PACK_TYPE_SUCCEDED"
export const CREATE_PACK_TYPE_FAILED = "packType@CREATE_PACK_TYPE_FAILED"

export const FETCH_PACK_TYPE_INIT = "packType@FETCH_PACK_TYPE_INIT"
export const FETCH_PACK_TYPE_SUCCEDED = "packType@FETCH_PACK_TYPE_SUCCEDED"
export const FETCH_PACK_TYPE_FAILED = "packType@FETCH_PACK_TYPE_FAILED"

export const EDIT_PACK_TYPE_INIT = "packType@EDIT_PACK_TYPE_INIT"
export const EDIT_PACK_TYPE_SUCCEDED = "packType@EDIT_PACK_TYPE_SUCCEDED"
export const EDIT_PACK_TYPE_FAILED = "packType@EDIT_PACK_TYPE_FAILED"

export const DELETE_PACK_TYPE_INIT = "packType@DELETE_PACK_TYPE_INIT"
export const DELETE_PACK_TYPE_SUCCEDED = "packType@DELETE_PACK_TYPE_SUCCEDED"
export const DELETE_PACK_TYPE_FAILED = "packType@DELETE_PACK_TYPE_FAILED"
