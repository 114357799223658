// faq
export const FETCH_FAQS_INIT = "faq@FETCH_FAQS_INIT"
export const FETCH_FAQS_SUCCEDED = "faq@FETCH_FAQS_SUCCEDED"
export const FETCH_FAQS_FAILED = "faq@FETCH_FAQS_FAILED"

export const CLEAR_FAQ = "faq@CLEAR_FAQ"

export const CREATE_FAQ_INIT = "faq@CREATE_FAQ_INIT"
export const CREATE_FAQ_SUCCEDED = "faq@CREATE_FAQ_SUCCEDED"
export const CREATE_FAQ_FAILED = "faq@CREATE_FAQ_FAILED"

export const FETCH_FAQ_INIT = "faq@FETCH_FAQ_INIT"
export const FETCH_FAQ_SUCCEDED = "faq@FETCH_FAQ_SUCCEDED"
export const FETCH_FAQ_FAILED = "faq@FETCH_FAQ_FAILED"

export const EDIT_FAQ_INIT = "faq@EDIT_FAQ_INIT"
export const EDIT_FAQ_SUCCEDED = "faq@EDIT_FAQ_SUCCEDED"
export const EDIT_FAQ_FAILED = "faq@EDIT_FAQ_FAILED"

export const DELETE_FAQ_INIT = "faq@DELETE_FAQ_INIT"
export const DELETE_FAQ_SUCCEDED = "faq@DELETE_FAQ_SUCCEDED"
export const DELETE_FAQ_FAILED = "faq@DELETE_FAQ_FAILED"
