// courier
export const FETCH_COURIERS_INIT = "courier@FETCH_COURIERS_INIT"
export const FETCH_COURIERS_SUCCEDED = "courier@FETCH_COURIERS_SUCCEDED"
export const FETCH_COURIERS_FAILED = "courier@FETCH_COURIERS_FAILED"

export const CLEAR_COURIER = "courier@CLEAR_COURIER"

export const CREATE_COURIER_INIT = "courier@CREATE_COURIER_INIT"
export const CREATE_COURIER_SUCCEDED = "courier@CREATE_COURIER_SUCCEDED"
export const CREATE_COURIER_FAILED = "courier@CREATE_COURIER_FAILED"

export const FETCH_COURIER_INIT = "courier@FETCH_COURIER_INIT"
export const FETCH_COURIER_SUCCEDED = "courier@FETCH_COURIER_SUCCEDED"
export const FETCH_COURIER_FAILED = "courier@FETCH_COURIER_FAILED"

export const EDIT_COURIER_INIT = "courier@EDIT_COURIER_INIT"
export const EDIT_COURIER_SUCCEDED = "courier@EDIT_COURIER_SUCCEDED"
export const EDIT_COURIER_FAILED = "courier@EDIT_COURIER_FAILED"

export const DELETE_COURIER_INIT = "courier@DELETE_COURIER_INIT"
export const DELETE_COURIER_SUCCEDED = "courier@DELETE_COURIER_SUCCEDED"
export const DELETE_COURIER_FAILED = "courier@DELETE_COURIER_FAILED"
