import { ACTIONS } from "./../constants"


const initialState = {
  options: [],
  option: {},
  optionTotalSize: 0,
  isFetching: false,
  isLoading: false,
  hasMore: true,
  success: {
    isDeleted: false,
    isCreated: false,
    isUpdated: false,
  },
  error: null
}


export default (state = initialState, action) => {
  const { payload, type } = action
  switch (type) {

    case ACTIONS.CLEAR_OPTION: {
      return { ...state, success: initialState.success, error: null, isFetching: false, isLoading: false }
    }

    case ACTIONS.FETCH_OPTIONS_INIT: {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_OPTIONS_SUCCEDED: {
      const { count, results } = payload
      return { ...state, optionTotalSize: count, options: results, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_OPTIONS_FAILED: {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.CREATE_OPTION_INIT: {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.CREATE_OPTION_SUCCEDED: {
      return { ...state, success: { ...state.success, isCreated: true }, isLoading: false, error: null }
    }
    case ACTIONS.CREATE_OPTION_FAILED: {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.EDIT_OPTION_INIT: {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.EDIT_OPTION_SUCCEDED: {
      return { ...state, success: { ...state.success, isUpdated: true }, isLoading: false, error: null }
    }
    case ACTIONS.EDIT_OPTION_FAILED: {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.FETCH_OPTION_INIT: {
      return { ...state, isLoading: true, success: initialState.success, option: null, error: null }
    }
    case ACTIONS.FETCH_OPTION_SUCCEDED: {
      return { ...state, option: payload, isLoading: false, error: null }
    }
    case ACTIONS.FETCH_OPTION_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.DELETE_OPTION_INIT: {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.DELETE_OPTION_SUCCEDED: {
      return { ...state, success: { ...state.success, isDeleted: true }, isLoading: false, error: null }
    }
    case ACTIONS.DELETE_OPTION_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    
    default: {
      return state
    }
  }
}
