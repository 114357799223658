// family
export const FETCH_FAMILYS_INIT = "family@FETCH_FAMILYS_INIT"
export const FETCH_FAMILYS_SUCCEDED = "family@FETCH_FAMILYS_SUCCEDED"
export const FETCH_FAMILYS_FAILED = "family@FETCH_FAMILYS_FAILED"

export const CLEAR_FAMILY = "family@CLEAR_FAMILY"

export const CREATE_FAMILY_INIT = "family@CREATE_FAMILY_INIT"
export const CREATE_FAMILY_SUCCEDED = "family@CREATE_FAMILY_SUCCEDED"
export const CREATE_FAMILY_FAILED = "family@CREATE_FAMILY_FAILED"

export const FETCH_FAMILY_INIT = "family@FETCH_FAMILY_INIT"
export const FETCH_FAMILY_SUCCEDED = "family@FETCH_FAMILY_SUCCEDED"
export const FETCH_FAMILY_FAILED = "family@FETCH_FAMILY_FAILED"

export const EDIT_FAMILY_INIT = "family@EDIT_FAMILY_INIT"
export const EDIT_FAMILY_SUCCEDED = "family@EDIT_FAMILY_SUCCEDED"
export const EDIT_FAMILY_FAILED = "family@EDIT_FAMILY_FAILED"

export const DELETE_FAMILY_INIT = "family@DELETE_FAMILY_INIT"
export const DELETE_FAMILY_SUCCEDED = "family@DELETE_FAMILY_SUCCEDED"
export const DELETE_FAMILY_FAILED = "family@DELETE_FAMILY_FAILED"
