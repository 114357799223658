// ADMIN
export const FETCH_ADMINS_INIT = "admin@FETCH_ADMINS_INIT"
export const FETCH_ADMINS_SUCCEDED = "admin@FETCH_ADMINS_SUCCEDED"
export const FETCH_ADMINS_FAILED = "admin@FETCH_ADMINS_FAILED"

export const CLEAR_ADMIN = "admin@CLEAR_ADMIN"

export const CREATE_ADMIN_INIT = "admin@CREATE_ADMIN_INIT"
export const CREATE_ADMIN_SUCCEDED = "admin@CREATE_ADMIN_SUCCEDED"
export const CREATE_ADMIN_FAILED = "admin@CREATE_ADMIN_FAILED"

export const REGENRATE_ADMIN_PASSWORD_INIT = "admin@REGENRATE_ADMIN_PASSWORD_INIT"
export const REGENRATE_ADMIN_PASSWORD_SUCCEDED = "admin@REGENRATE_ADMIN_PASSWORD_SUCCEDED"
export const REGENRATE_ADMIN_PASSWORD_FAILED = "admin@REGENRATE_ADMIN_PASSWORD_FAILED"

export const FETCH_ADMIN_INIT = "admin@FETCH_ADMIN_INIT"
export const FETCH_ADMIN_SUCCEDED = "admin@FETCH_ADMIN_SUCCEDED"
export const FETCH_ADMIN_FAILED = "admin@FETCH_ADMIN_FAILED"

export const FCM_TOKEN_INIT = "admin@FCM_TOKEN_INIT"
export const FCM_TOKEN_SUCCEDED = "admin@FCM_TOKEN_SUCCEDED"
export const FCM_TOKEN_FAILED = "admin@FCM_TOKEN_FAILED"


export const ADMINS_NOTIFICATION_INIT = "admin@ADMINS_NOTIFICATION_INIT"
export const ADMINS_NOTIFICATION_SUCCEDED = "admin@ADMINS_NOTIFICATION_SUCCEDED"
export const ADMINS_NOTIFICATION_FAILED = "admin@ADMINS_NOTIFICATION_FAILED"

export const READ_NOTIFICATION_INIT = "notification@READ_NOTIFICATION_INIT"
export const READ_NOTIFICATION_SUCCEDED = "notification@READ_NOTIFICATION_SUCCEDED"
export const READ_NOTIFICATION_FAILED = "notification@READ_NOTIFICATION_FAILED"

export const EDIT_ADMIN_INIT = "admin@EDIT_ADMIN_INIT"
export const EDIT_ADMIN_SUCCEDED = "admin@EDIT_ADMIN_SUCCEDED"
export const EDIT_ADMIN_FAILED = "admin@EDIT_ADMIN_FAILED"

export const DISABLE_ADMIN_INIT = "admin@DISABLE_ADMIN_INIT"
export const DISABLE_ADMIN_SUCCEDED = "admin@DISABLE_ADMIN_SUCCEDED"
export const DISABLE_ADMIN_FAILED = "admin@DISABLE_ADMIN_FAILED"

export const ENABLE_ADMIN_INIT = "admin@ENABLE_ADMIN_INIT"
export const ENABLE_ADMIN_SUCCEDED = "admin@ENABLE_ADMIN_SUCCEDED"
export const ENABLE_ADMIN_FAILED = "admin@ENABLE_ADMIN_FAILED"

export const DISABLE_ADMINS_INIT = "admin@DISABLE_ADMINS_INIT"
export const DISABLE_ADMINS_SUCCEDED = "admin@DISABLE_ADMINS_SUCCEDED"
export const DISABLE_ADMINS_FAILED = "admin@DISABLE_ADMINS_FAILED"

export const ENABLE_ADMINS_INIT = "admin@ENABLE_ADMINS_INIT"
export const ENABLE_ADMINS_SUCCEDED = "admin@ENABLE_ADMINS_SUCCEDED"
export const ENABLE_ADMINS_FAILED = "admin@ENABLE_ADMINS_FAILED"

export const DELETE_ADMIN_INIT = "admin@DELETE_ADMIN_INIT"
export const DELETE_ADMIN_SUCCEDED = "admin@DELETE_ADMIN_SUCCEDED"
export const DELETE_ADMIN_FAILED = "admin@DELETE_ADMIN_FAILED"

export const SOFT_DELETE_ADMIN_INIT = "admin@SOFT_DELETE_ADMIN_INIT"
export const SOFT_DELETE_ADMIN_SUCCEDED = "admin@SOFT_DELETE_ADMIN_SUCCEDED"
export const SOFT_DELETE_ADMIN_FAILED = "admin@SOFT_DELETE_ADMIN_FAILED"

export const DELETE_ADMINS_INIT = "admin@DELETE_ADMINS_INIT"
export const DELETE_ADMINS_SUCCEDED = "admin@DELETE_ADMINS_SUCCEDED"
export const DELETE_ADMINS_FAILED = "admin@DELETE_ADMINS_FAILED"

export const SOFT_DELETE_ADMINS_INIT = "admin@SOFT_DELETE_ADMINS_INIT"
export const SOFT_DELETE_ADMINS_SUCCEDED = "admin@SOFT_DELETE_ADMINS_SUCCEDED"
export const SOFT_DELETE_ADMINS_FAILED = "admin@SOFT_DELETE_ADMINS_FAILED"
