import { CREATE, UPDATE, VIEW, DELETE, UNDELETE,/*VIEW_UPDATE, ACTIVATE, DEACTIVATE,*/ } from "./Permissions"


export const USER = {
  module: "user",
  permissions: {
    [VIEW]: "view_user",
    [CREATE]: "add_user",
    [UPDATE]: "change_user",
    //[ACTIVATE]: "activate_user",
    //[DEACTIVATE]: "delete_user",
    [DELETE]: "delete_user",
    // [UNDELETE]: "undelete_user",
  },
}
// Admins
export const ADMIN = {
  module: "admin",
  permissions: {
    [VIEW]: "view_admin",
    [CREATE]: "add_admin",
    [UPDATE]: "change_admin",
    [DELETE]: "delete_admin",
  },
}

export const USER_GROUP = {
  module: "role",
  permissions: {
    [VIEW]: "view_role",
    [CREATE]: "add_role",
    [UPDATE]: "change_role",
    [DELETE]: "delete_role"
  }
}

export const ADMIN_GROUP = {
  module: "Adminrole",
  permissions: {
    [VIEW]: "view_Adminrole",
    [CREATE]: "add_Adminrole",
    [UPDATE]: "change_Adminrole",
    [DELETE]: "delete_Adminrole"
  }
}

export const TENANT = {
  module: "tenant",
  permissions: {
    [VIEW]: "view_tenant",
    [CREATE]: "add_tenant",
    [UPDATE]: "change_tenant",
    [DELETE]: "delete_tenant"
  }
}

export const ACTIVITY = {
  module: "activity",
  permissions: {
    [VIEW]: "view_activity",
    [CREATE]: "add_activity",
    [UPDATE]: "change_activity",
    [DELETE]: "delete_activity"
  }
}


export const OPTION = {
  module: "option",
  permissions: {
    [VIEW]: "view_option",
    [CREATE]: "add_option",
    [UPDATE]: "change_option",
    [DELETE]: "delete_option"
  }
}


//customer
export const GROUP_CLIENT = {
  module: "group_client",
  permissions: {
    [VIEW]: "view_customer",
    [CREATE]: "add_customer",
    [UPDATE]: "change_customer",
    [DELETE]: "delete_customer"
  }
}
//order
export const DISCOUNT = {
  module: "discount",
  permissions: {
    [VIEW]: "view_order",
    [CREATE]: "add_order",
    [UPDATE]: "change_order",
    [DELETE]: "delete_order"
  }
}
//product
export const COLOR = {
  module: "color",
  permissions: {
    [VIEW]: "view_product",
    [CREATE]: "add_product",
    [UPDATE]: "change_product",
    [DELETE]: "delete_product"
  }
}


//product
export const PACK_TYPE = {
  module: "pack_type",
  permissions: {
    [VIEW]: "view_product",
    [CREATE]: "add_product",
    [UPDATE]: "change_product",
    [DELETE]: "delete_product"
  }
}

export const COURIER = {
  module: "delivery",
  permissions: {
    [VIEW]: "view_delivery",
    [CREATE]: "add_delivery",
    [UPDATE]: "change_delivery",
    [DELETE]: "delete_delivery"
  }
}

export const ORDER = {
  module: "order",
  permissions: {
    [VIEW]: "view_order",
    [CREATE]: "add_order",
    [UPDATE]: "change_order",
    [DELETE]: "delete_order"
  }
}

export const PRODUCT_RETURN = {
  module: "retours",
  permissions: {
    [VIEW]: "view_order",
    [CREATE]: "add_order",
    [UPDATE]: "change_order",
    [DELETE]: "delete_order"
  }
}

export const FAQ = {
  module: "faq",
  permissions: {
    [VIEW]: "view_faq",
    [CREATE]: "add_faq",
    [UPDATE]: "change_faq",
    [DELETE]: "delete_faq"
  }
  // permissions: {
  //   [VIEW]: "view_faq",
  //   [CREATE]: "add_faq",
  //   [UPDATE]: "change_faq",
  //   [DELETE]: "delete_faq"
  // }
}
//product
export const QUALITY = {
  module: "quality",
  permissions: {
    [VIEW]: "view_product",
    [CREATE]: "add_product",
    [UPDATE]: "change_product",
    [DELETE]: "delete_product"
  }
}
//feeedback
export const RECLAMATION = {
  module: "reclamation",
  permissions: {
    [VIEW]: "view_feedback",
    [CREATE]: "add_feedback",
    [UPDATE]: "change_feedback",
    [DELETE]: "delete_feedback"
  }
}
//order
export const TAXATION = {
  module: "taxation",
  permissions: {
    [VIEW]: "view_order",
    [CREATE]: "add_order",
    [UPDATE]: "change_order",
    [DELETE]: "delete_order"
  }
}

//family
export const FAMILY = {
  module: "family",
  permissions: {
    [VIEW]: "view_family",
    [CREATE]: "add_family",
    [UPDATE]: "change_family",
    [DELETE]: "delete_family"
  }
}

export const CATEGORY = {
  module: "category",
  permissions: {
    [VIEW]: "view_category",
    [CREATE]: "add_category",
    [UPDATE]: "change_category",
    [DELETE]: "delete_category"
  }
}

export const PRODUCT = {
  module: "product",
  permissions: {
    [VIEW]: "view_product",
    [CREATE]: "add_product",
    [UPDATE]: "change_product",
    [DELETE]: "delete_product"
  }
}

export const CLIENT = {
  module: "client",
  permissions: {
    [VIEW]: "view_customer",
    [CREATE]: "add_customer",
    [UPDATE]: "change_customer",
    [DELETE]: "delete_customer"
  }
}

//order
// export const FISCALTEMBER = {
//   module: "fiscalTember",
//   permissions: {
//     [VIEW]: "view_order",
//     [CREATE]: "add_order",
//     [UPDATE]: "change_order",
//     [DELETE]: "delete_order"
//   }
// }