export const USERS = "/users"
export const USER = "/users/:param"

export const USER_ACTIVATE = "/users/:param/activate"
export const USER_DEACTIVATE = "/users/:param/deactivate"

export const USER_DELETE = "/users/:param"
export const USER_UNDELETE = "/users/:param/undelete"

export const USERS_ACTIVATE = "/users/activate"
export const USERS_DEACTIVATE = "/users/deactivate"

export const USERS_UNDELETE = "/users/undelete"
export const USERS_DELETE = "/users/delete"

export const USERS_NOTIFICATION = "/api/notifications"
export const READ_NOTIFICATION = "/api/notifications/:param"

export const FCM_TOKEN = "/auth/me/fcm-token"
