// category
export const FETCH_CATEGORYS_INIT = "category@FETCH_CATEGORYS_INIT"
export const FETCH_CATEGORYS_SUCCEDED = "category@FETCH_CATEGORYS_SUCCEDED"
export const FETCH_CATEGORYS_FAILED = "category@FETCH_CATEGORYS_FAILED"

export const CLEAR_CATEGORY = "category@CLEAR_CATEGORY"

export const CREATE_CATEGORY_INIT = "category@CREATE_CATEGORY_INIT"
export const CREATE_CATEGORY_SUCCEDED = "category@CREATE_CATEGORY_SUCCEDED"
export const CREATE_CATEGORY_FAILED = "category@CREATE_CATEGORY_FAILED"

export const FETCH_CATEGORY_INIT = "category@FETCH_CATEGORY_INIT"
export const FETCH_CATEGORY_SUCCEDED = "category@FETCH_CATEGORY_SUCCEDED"
export const FETCH_CATEGORY_FAILED = "category@FETCH_CATEGORY_FAILED"

export const EDIT_CATEGORY_INIT = "category@EDIT_CATEGORY_INIT"
export const EDIT_CATEGORY_SUCCEDED = "category@EDIT_CATEGORY_SUCCEDED"
export const EDIT_CATEGORY_FAILED = "category@EDIT_CATEGORY_FAILED"

export const DELETE_CATEGORY_INIT = "category@DELETE_CATEGORY_INIT"
export const DELETE_CATEGORY_SUCCEDED = "category@DELETE_CATEGORY_SUCCEDED"
export const DELETE_CATEGORY_FAILED = "category@DELETE_CATEGORY_FAILED"
