export const APP_VERSION = "v1.0.0-alpha"

export const FR = "fr"
export const AR = "ar-tn"
export const EN = "en"

export const FILE_ALREADY_UPLOADED = "file@file_already_uploaded"

export const ARCHIVED = 0
export const NOT_ARCHIVED = 1
export const ARCHIVED_PARAM = "active"

export * from "./API"
export * from "./ErrorCode"
export * from "./Permissions"


export const MODULES_PERMISSIONS = require("./ModulesPermissions")
