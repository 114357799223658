import { ACTIONS } from "../constants"

const initialState = {
  admins: [],
  notifications : [],
  admin: {},
  totalSize: 0,
  totalNotifications: 0,
  isFetching: false,
  isLoading: false,
  hasMore: true,
  success: {
    isDeactivated: false,
    isActivated: false,
    isCreated: false,
    isUpdated: false,
    isDeleted: false,
    isReseted: false,
    isUndeleted: false,
  },
  error: null,
}

export default (state = initialState, action) => {
  const { payload, type } = action
  switch (type) {
    case ACTIONS.CLEAR_ADMIN: {
      return {
        ...state,
        success: initialState.success,
        error: null,
        isFetching: false,
        isLoading: false,
      }
    }

    case ACTIONS.FETCH_ADMINS_INIT: {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_ADMINS_SUCCEDED: {
      const { count, results } = payload
      return {
        ...state,
        totalSize: count,
        admins: results,
        isFetching: false,
        error: null,
      }
    }
    case ACTIONS.FETCH_ADMINS_FAILED: {
      return { ...state, isFetching: false, error: payload }
    }


    case ACTIONS.ADMINS_NOTIFICATION_INIT: {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.ADMINS_NOTIFICATION_SUCCEDED: {
      const { count, results } = payload
      return {
        ...state,
        totalNotifications: count,
        notifications: results,
        isFetching: false,
        error: null,
      }
    }
    case ACTIONS.ADMINS_NOTIFICATION_FAILED: {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.READ_NOTIFICATION_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.READ_NOTIFICATION_SUCCEDED: {
      return { ...state, isFetching: false, notifications: state.notifications.map((notification) => notification.id !== payload.id ? notification : ({ ...notification, ...payload}))};
    }
    case ACTIONS.READ_NOTIFICATION_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.CREATE_ADMIN_INIT: {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.CREATE_ADMIN_SUCCEDED: {
      return { ...state, success: { ...state.success, isCreated: true }, isLoading: false, error: null }
    }
    case ACTIONS.CREATE_ADMIN_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false }
    }


    case ACTIONS.FCM_TOKEN_INIT: {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.FCM_TOKEN_SUCCEDED: {
      return { ...state, success: { ...state.success, isCreated: true }, isLoading: false, error: null }
    }
    case ACTIONS.FCM_TOKEN_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false }
    }

    case ACTIONS.EDIT_ADMIN_INIT: {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.EDIT_ADMIN_SUCCEDED: {
      return {
        ...state,
        admin: payload,
        success: { ...state.success, isUpdated: true },
        isLoading: false,
        error: null,
      }
    }
    case ACTIONS.EDIT_ADMIN_FAILED: {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.FETCH_ADMIN_INIT: {
      return { ...state, isFetching: true, admin: null, error: null }
    }
    case ACTIONS.FETCH_ADMIN_SUCCEDED: {
      return { ...state, admin: payload, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_ADMIN_FAILED: {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.REGENRATE_ADMIN_PASSWORD_INIT: {
      return { ...state, isLoading: true, admin: null, error: null, success: initialState.success }
    }
    case ACTIONS.REGENRATE_ADMIN_PASSWORD_SUCCEDED: {
      return { ...state, success: { ...state.success, isReseted: true }, isLoading: false, error: null }
    }
    case ACTIONS.REGENRATE_ADMIN_PASSWORD_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.DELETE_ADMIN_INIT: {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.DELETE_ADMIN_SUCCEDED: {
      return { ...state, success: { ...state.success, isDeleted: true }, isLoading: false, error: null }
    }
    case ACTIONS.DELETE_ADMIN_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.DELETE_ADMINS_INIT: {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.DELETE_ADMINS_SUCCEDED: {
      return { ...state, success: { ...state.success, isDeleted: true }, isLoading: false, error: null }
    }
    case ACTIONS.DELETE_ADMINS_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.DISABLE_ADMIN_INIT: {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.DISABLE_ADMIN_SUCCEDED: {
      return { ...state, success: { ...state.success, isDeactivated: true }, isLoading: false, error: null }
    }
    case ACTIONS.DISABLE_ADMIN_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.DISABLE_ADMINS_INIT: {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.DISABLE_ADMINS_SUCCEDED: {
      return { ...state, success: { ...state.success, isDeactivated: true }, isLoading: false, error: null }
    }
    case ACTIONS.DISABLE_ADMINS_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.ENABLE_ADMIN_INIT: {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.ENABLE_ADMIN_SUCCEDED: {
      return { ...state, success: { ...state.success, isActivated: true }, isLoading: false, error: null }
    }
    case ACTIONS.ENABLE_ADMIN_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.ENABLE_ADMINS_INIT: {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.ENABLE_ADMINS_SUCCEDED: {
      return { ...state, success: { ...state.success, isActivated: true }, isLoading: false, error: null }
    }
    case ACTIONS.ENABLE_ADMINS_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.SOFT_DELETE_ADMIN_INIT: {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.SOFT_DELETE_ADMIN_SUCCEDED: {
      return { ...state, success: { ...state.success, isUndeleted: true }, isLoading: false, error: null }
    }
    case ACTIONS.SOFT_DELETE_ADMIN_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.SOFT_DELETE_ADMINS_INIT: {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.SOFT_DELETE_ADMINS_SUCCEDED: {
      return { ...state, success: { ...state.success, isUndeleted: true }, isLoading: false, error: null }
    }
    case ACTIONS.SOFT_DELETE_ADMINS_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }



    default: {
      return state
    }
  }
}
