// taxation
export const FETCH_TAXATIONS_INIT = "taxation@FETCH_TAXATIONS_INIT"
export const FETCH_TAXATIONS_SUCCEDED = "taxation@FETCH_TAXATIONS_SUCCEDED"
export const FETCH_TAXATIONS_FAILED = "taxation@FETCH_TAXATIONS_FAILED"

export const CLEAR_TAXATION = "taxation@CLEAR_TAXATION"

export const CREATE_TAXATION_INIT = "taxation@CREATE_TAXATION_INIT"
export const CREATE_TAXATION_SUCCEDED = "taxation@CREATE_TAXATION_SUCCEDED"
export const CREATE_TAXATION_FAILED = "taxation@CREATE_TAXATION_FAILED"

export const FETCH_TAXATION_INIT = "taxation@FETCH_TAXATION_INIT"
export const FETCH_TAXATION_SUCCEDED = "taxation@FETCH_TAXATION_SUCCEDED"
export const FETCH_TAXATION_FAILED = "taxation@FETCH_TAXATION_FAILED"

export const EDIT_TAXATION_INIT = "taxation@EDIT_TAXATION_INIT"
export const EDIT_TAXATION_SUCCEDED = "taxation@EDIT_TAXATION_SUCCEDED"
export const EDIT_TAXATION_FAILED = "taxation@EDIT_TAXATION_FAILED"

export const DELETE_TAXATION_INIT = "taxation@DELETE_TAXATION_INIT"
export const DELETE_TAXATION_SUCCEDED = "taxation@DELETE_TAXATION_SUCCEDED"
export const DELETE_TAXATION_FAILED = "taxation@DELETE_TAXATION_FAILED"
