import {API_BASE_URL} from "src/constants"
import axios from "axios"


export const removeCSSClass = (ele, cls) => {
  const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)")
  ele.className = ele.className.replace(reg, " ")
}

export const addCSSClass = (ele, cls) => {
  ele.classList.add(cls)
}

export const isValidURL = (str) => {
  /*var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i') // fragment locator
    *///return pattern.test(str)
  return str.includes(API_BASE_URL)
}

export const isFileImage = (file) => file instanceof File && file['type'].split('/')[0] === 'image'

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

const getBlobFromUrl = (imageUrl) => new Promise((resolve, reject) => {
  axios.get(toMediaUrl(imageUrl), {
    responseType: 'blob',
    timeout: 30000,
  })
    .then((response) => resolve(response.data))
    .catch((err) => reject(err))
})

export const convertUrlToImageData = (imageUrl) => new Promise((resolve, reject) => {
  getBlobFromUrl(imageUrl).then((blob) => {
    resolve(new File([blob], imageUrl.substring(imageUrl.lastIndexOf('/')+1) , {
      type: blob.type,
      lastModified: blob.lastModified,
      size: blob.size
    }))
  }).catch((err) => reject(err))
})

export const convertUrlToImagesData = (images) => new Promise((resolve, reject) => {
  const promises = []
  images.forEach((imageUrl) => {
    promises.push(convertUrlToImageData(imageUrl))
  })
  Promise.all(promises).then((values) => resolve(values)).catch((err) => reject(err))
})

const imageExistPromise = (image_url) => new Promise((resolve,reject) => {

  var http = new XMLHttpRequest();

  http.open('HEAD', image_url, false);
  http.onload = function () {
    if (http.status !== 404) {
      resolve(true);
    } else {
      reject(false);
    }
  };
  http.send();
})

export const imageCheck = (image_url) => {

  var http = new XMLHttpRequest();

  http.open('HEAD', image_url, false);
  http.send();
  return http.status !== 404

}


export const imageExists = async (image_url) => await imageExistPromise(image_url)

export const getBase64 = (file, callback) => {
  let reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => callback(reader.result)
  //reader.onerror = error => {}
}

export const toAbsoluteUrl = pathname => {
  const PUBLIC_URL = window.location.origin
  return PUBLIC_URL + pathname
}

export const toMediaUrl = (pathname) => `${API_BASE_URL}/products/image/get?imgPath=${pathname}`
export const toMediaUrlTenant = (pathname) => `${API_BASE_URL}/tenants/image/get?imgPath=${pathname}`


