// productReturn
export const FETCH_PRODUCT_RETURNS_INIT = "productReturn@FETCH_PRODUCT_RETURNS_INIT"
export const FETCH_PRODUCT_RETURNS_SUCCEDED = "productReturn@FETCH_PRODUCT_RETURNS_SUCCEDED"
export const FETCH_PRODUCT_RETURNS_FAILED = "productReturn@FETCH_PRODUCT_RETURNS_FAILED"

export const CLEAR_PRODUCT_RETURN = "productReturn@CLEAR_PRODUCT_RETURN"

export const CREATE_PRODUCT_RETURN_INIT = "productReturn@CREATE_PRODUCT_RETURN_INIT"
export const CREATE_PRODUCT_RETURN_SUCCEDED = "productReturn@CREATE_PRODUCT_RETURN_SUCCEDED"
export const CREATE_PRODUCT_RETURN_FAILED = "productReturn@CREATE_PRODUCT_RETURN_FAILED"

export const FETCH_PRODUCT_RETURN_INIT = "productReturn@FETCH_PRODUCT_RETURN_INIT"
export const FETCH_PRODUCT_RETURN_SUCCEDED = "productReturn@FETCH_PRODUCT_RETURN_SUCCEDED"
export const FETCH_PRODUCT_RETURN_FAILED = "productReturn@FETCH_PRODUCT_RETURN_FAILED"

export const EDIT_PRODUCT_RETURN_INIT = "productReturn@EDIT_PRODUCT_RETURN_INIT"
export const EDIT_PRODUCT_RETURN_SUCCEDED = "productReturn@EDIT_PRODUCT_RETURN_SUCCEDED"
export const EDIT_PRODUCT_RETURN_FAILED = "productReturn@EDIT_PRODUCT_RETURN_FAILED"

export const DELETE_PRODUCT_RETURN_INIT = "productReturn@DELETE_PRODUCT_RETURN_INIT"
export const DELETE_PRODUCT_RETURN_SUCCEDED = "productReturn@DELETE_PRODUCT_RETURN_SUCCEDED"
export const DELETE_PRODUCT_RETURN_FAILED = "productReturn@DELETE_PRODUCT_RETURN_FAILED"
