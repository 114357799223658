import { ACTIONS } from "../constants"


const initialState = {
  activities: [],
  activity: {},
  activityTotalSize: 0,
  isFetching: false,
  isLoading: false,
  hasMore: true,
  success: {
    isDeleted: false,
    isCreated: false,
    isUpdated: false,
  },
  error: null
}


export default (state = initialState, action) => {
  const { payload, type } = action
  switch (type) {

    case ACTIONS.CLEAR_ACTIVITY: {
      return { ...state, success: initialState.success, error: null, isFetching: false, isLoading: false }
    }

    case ACTIONS.FETCH_ACTIVITIES_INIT: {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_ACTIVITIES_SUCCEDED: {
      const { count, results } = payload
      return { ...state, activityTotalSize: count, activities: results, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_ACTIVITIES_FAILED: {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.CREATE_ACTIVITY_INIT: {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.CREATE_ACTIVITY_SUCCEDED: {
      return { ...state, success: { ...state.success, isCreated: true }, isLoading: false, error: null }
    }
    case ACTIONS.CREATE_ACTIVITY_FAILED: {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.EDIT_ACTIVITY_INIT: {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.EDIT_ACTIVITY_SUCCEDED: {
      return { ...state, success: { ...state.success, isUpdated: true }, isLoading: false, error: null }
    }
    case ACTIONS.EDIT_ACTIVITY_FAILED: {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.FETCH_ACTIVITY_INIT: {
      return { ...state, isLoading: true, success: initialState.success, activity: null, error: null }
    }
    case ACTIONS.FETCH_ACTIVITY_SUCCEDED: {
      return { ...state, activity: payload, isLoading: false, error: null }
    }
    case ACTIONS.FETCH_ACTIVITY_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.DELETE_ACTIVITY_INIT: {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.DELETE_ACTIVITY_SUCCEDED: {
      return { ...state, success: { ...state.success, isDeleted: true }, isLoading: false, error: null }
    }
    case ACTIONS.DELETE_ACTIVITY_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    
    default: {
      return state
    }
  }
}
