// order
export const FETCH_ORDERS_INIT = "order@FETCH_ORDERS_INIT"
export const FETCH_ORDERS_SUCCEDED = "order@FETCH_ORDERS_SUCCEDED"
export const FETCH_ORDERS_FAILED = "order@FETCH_ORDERS_FAILED"

export const CLEAR_ORDER = "order@CLEAR_ORDER"

export const CREATE_ORDER_INIT = "order@CREATE_ORDER_INIT"
export const CREATE_ORDER_SUCCEDED = "order@CREATE_ORDER_SUCCEDED"
export const CREATE_ORDER_FAILED = "order@CREATE_ORDER_FAILED"

export const FETCH_ORDER_INIT = "order@FETCH_ORDER_INIT"
export const FETCH_ORDER_SUCCEDED = "order@FETCH_ORDER_SUCCEDED"
export const FETCH_ORDER_FAILED = "order@FETCH_ORDER_FAILED"

export const EDIT_ORDER_INIT = "order@EDIT_ORDER_INIT"
export const EDIT_ORDER_SUCCEDED = "order@EDIT_ORDER_SUCCEDED"
export const EDIT_ORDER_FAILED = "order@EDIT_ORDER_FAILED"

export const DELETE_ORDER_INIT = "order@DELETE_ORDER_INIT"
export const DELETE_ORDER_SUCCEDED = "order@DELETE_ORDER_SUCCEDED"
export const DELETE_ORDER_FAILED = "order@DELETE_ORDER_FAILED"
