// option
export const FETCH_OPTIONS_INIT = "option@FETCH_OPTIONS_INIT"
export const FETCH_OPTIONS_SUCCEDED = "option@FETCH_OPTIONS_SUCCEDED"
export const FETCH_OPTIONS_FAILED = "option@FETCH_OPTIONS_FAILED"

export const CLEAR_OPTION = "option@CLEAR_OPTION"

export const CREATE_OPTION_INIT = "option@CREATE_OPTION_INIT"
export const CREATE_OPTION_SUCCEDED = "option@CREATE_OPTION_SUCCEDED"
export const CREATE_OPTION_FAILED = "option@CREATE_OPTION_FAILED"

export const FETCH_OPTION_INIT = "option@FETCH_OPTION_INIT"
export const FETCH_OPTION_SUCCEDED = "option@FETCH_OPTION_SUCCEDED"
export const FETCH_OPTION_FAILED = "option@FETCH_OPTION_FAILED"

export const EDIT_OPTION_INIT = "option@EDIT_OPTION_INIT"
export const EDIT_OPTION_SUCCEDED = "option@EDIT_OPTION_SUCCEDED"
export const EDIT_OPTION_FAILED = "option@EDIT_OPTION_FAILED"

export const DELETE_OPTION_INIT = "option@DELETE_OPTION_INIT"
export const DELETE_OPTION_SUCCEDED = "option@DELETE_OPTION_SUCCEDED"
export const DELETE_OPTION_FAILED = "option@DELETE_OPTION_FAILED"
