import { ACTIONS } from "../constants"


const initialState = {
  tenants: [],
  tenant: {},
  tenantTotalSize: 0,
  isFetching: false,
  isLoading: false,
  hasMore: true,
  success: {
    isDeleted: false,
    isCreated: false,
    isUpdated: false,
  },
  error: null
}


export default (state = initialState, action) => {
  const { payload, type } = action
  switch (type) {

    case ACTIONS.CLEAR_TENANT: {
      return { ...state, success: initialState.success, error: null, isFetching: false, isLoading: false }
    }

    case ACTIONS.FETCH_TENANTS_INIT: {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_TENANTS_SUCCEDED: {
      const { count, results } = payload
      return { ...state, tenantTotalSize: count, tenants: results, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_TENANTS_FAILED: {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.CREATE_TENANT_INIT: {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.CREATE_TENANT_SUCCEDED: {
      return { ...state, success: { ...state.success, isCreated: true }, isLoading: false, error: null }
    }
    case ACTIONS.CREATE_TENANT_FAILED: {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.EDIT_TENANT_INIT: {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.EDIT_TENANT_SUCCEDED: {
      return { ...state, success: { ...state.success, isUpdated: true }, isLoading: false, error: null }
    }
    case ACTIONS.EDIT_TENANT_FAILED: {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.FETCH_TENANT_INIT: {
      return { ...state, isLoading: true, success: initialState.success, tenant: null, error: null }
    }
    case ACTIONS.FETCH_TENANT_SUCCEDED: {
      return { ...state, tenant: payload, isLoading: false, error: null }
    }
    case ACTIONS.FETCH_TENANT_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.DELETE_TENANT_INIT: {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.DELETE_TENANT_SUCCEDED: {
      return { ...state, success: { ...state.success, isDeleted: true }, isLoading: false, error: null }
    }
    case ACTIONS.DELETE_TENANT_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }

    
    default: {
      return state
    }
  }
}
