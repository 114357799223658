// adminGroup
export const FETCH_ADMIN_GROUPS_INIT = "adminGroup@FETCH_ADMIN_GROUPS_INIT"
export const FETCH_ADMIN_GROUPS_SUCCEDED = "adminGroup@FETCH_ADMIN_GROUPS_SUCCEDED"
export const FETCH_ADMIN_GROUPS_FAILED = "adminGroup@FETCH_ADMIN_GROUPS_FAILED"

export const CLEAR_ADMIN_GROUP = "adminGroup@CLEAR_ADMIN_GROUP"

export const CREATE_ADMIN_GROUP_INIT = "adminGroup@CREATE_ADMIN_GROUP_INIT"
export const CREATE_ADMIN_GROUP_SUCCEDED = "adminGroup@CREATE_ADMIN_GROUP_SUCCEDED"
export const CREATE_ADMIN_GROUP_FAILED = "adminGroup@CREATE_ADMIN_GROUP_FAILED"

export const FETCH_ADMIN_GROUP_INIT = "adminGroup@FETCH_ADMIN_GROUP_INIT"
export const FETCH_ADMIN_GROUP_SUCCEDED = "adminGroup@FETCH_ADMIN_GROUP_SUCCEDED"
export const FETCH_ADMIN_GROUP_FAILED = "adminGroup@FETCH_ADMIN_GROUP_FAILED"

export const EDIT_ADMIN_GROUP_INIT = "adminGroup@EDIT_ADMIN_GROUP_INIT"
export const EDIT_ADMIN_GROUP_SUCCEDED = "adminGroup@EDIT_ADMIN_GROUP_SUCCEDED"
export const EDIT_ADMIN_GROUP_FAILED = "adminGroup@EDIT_ADMIN_GROUP_FAILED"

export const DELETE_ADMIN_GROUP_INIT = "adminGroup@DELETE_ADMIN_GROUP_INIT"
export const DELETE_ADMIN_GROUP_SUCCEDED = "adminGroup@DELETE_ADMIN_GROUP_SUCCEDED"
export const DELETE_ADMIN_GROUP_FAILED = "adminGroup@DELETE_ADMIN_GROUP_FAILED"
