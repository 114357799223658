/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux"

import layoutReducer from "./layout/store/reducers"

// Reducers
import profileReducer from "./containers/profile/store/reducers"
import userReducer from "./containers/user/store/reducers"
import userGroupReducer from "./containers/user-group/store/reducers"
import adminGroupReducer from "./containers/admin-group/store/reducers"

import adminReducer from "./containers/admins/store/reducers"
import colorReducer from "./containers/color/store/reducers"
import optionReducer from "./containers/option/store/reducers"
import activityReducer from "./containers/activity/store/reducers"
import tenantReducer from "./containers/tenant/store/reducers"
import packTypeReducer from "./containers/pack-type/store/reducers"
import categoryReducer from "./containers/category/store/reducers"
import qualityReducer from "./containers/quality/store/reducers"
import familyReducer from "./containers/family/store/reducers"

import clientReducer from "./containers/client/store/reducers"
import groupClientReducer from "./containers/group-client/store/reducers"

import productReducer from "./containers/product/store/reducers"
import courierReducer from "./containers/courier/store/reducers"
import orderReducer from "./containers/order/store/reducers"
import discountReducer from "./containers/discount/store/reducers"
import taxationReducer from "./containers/taxation/store/reducers"
import productReturnReducer from "./containers/product-return/store/reducers"
import reclamationReducer from "./containers/reclamation/store/reducers"
import faqReducer from "./containers/faq/store/reducers"
import homeReducer from "./containers/home/store/reducers"



/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default combineReducers({
  layout: layoutReducer,
  user: userReducer,
  admin: adminReducer,
  packType: packTypeReducer,
  profile: profileReducer,
  userGroup: userGroupReducer,
  adminGroup: adminGroupReducer,
  color: colorReducer,
  activity: activityReducer,
  tenant: tenantReducer,
  family: familyReducer,
  option: optionReducer,
  category: categoryReducer,
  quality: qualityReducer,
  client: clientReducer,
  courier: courierReducer,
  order: orderReducer,
  groupClient: groupClientReducer,
  product: productReducer,
  discount : discountReducer,
  taxation : taxationReducer,
  productReturn: productReturnReducer,
  reclamation : reclamationReducer,
  faq : faqReducer ,
  home : homeReducer

})

