import { ACTIONS } from "../constants"


const initialState = { 
  clients: [], 
  clientOrder: [],
  clientDevis : [],
  clientReturn:[],
  client: {}, 
  clientOrderTotalSize: 0,
  clientDevisTotalSize: 0,
  clientReturnTotalSize: 0,
  totalSize: 0,
  isFetching: false, 
  isLoading: false,
  hasMore: true, 
  success: {
    isCreated: false,
    isUpdated: false,
    isDeleted: false
  },
  error: null
}


export default (state = initialState , action) => {
  const { payload, type } = action
  switch (type) {

    case ACTIONS.CLEAR_CLIENT : {
      return { ...state, success: initialState.success, error: null, isFetching: false, isLoading: false }
    }

    case ACTIONS.FETCH_CLIENTS_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_CLIENTS_SUCCEDED : {
      const { count, results } = payload
      return { ...state, totalSize: count, clients: results, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_CLIENTS_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.FETCH_CLIENTSORDER_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_CLIENTSORDER_SUCCEDED : {
      const { count, results } = payload
      return { ...state, clientOrderTotalSize: count, clientOrder: results, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_CLIENTSORDER_FAILED : {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.FETCH_CLIENTSDEVIS_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_CLIENTSDEVIS_SUCCEDED : {
      const { count, results } = payload
      return { ...state, clientDevisTotalSize: count, clientDevis: results, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_CLIENTSDEVIS__FAILED : {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.FETCH_CLIENTRETURN_INIT : {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_CLIENTRETURN_SUCCEDED : {
      const { count, results } = payload
      return { ...state, clientReturnTotalSize: count, clientReturn: results, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_CLIENTRETURN__FAILED : {
      return { ...state, isFetching: false, error: payload }
    }


    case ACTIONS.CREATE_CLIENT_INIT : {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.CREATE_CLIENT_SUCCEDED : {
      return { ...state, success: { ...state.success, isCreated: true }, isLoading: false, error: null }
    }
    case ACTIONS.CREATE_CLIENT_FAILED : {
      return { ...state, error: payload, isLoading: false, success: false }
    }

    case ACTIONS.EDIT_CLIENT_INIT : {      
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.EDIT_CLIENT_SUCCEDED : {
      return { ...state, success: { ...state.success, isUpdated: true }, isLoading: false, error: null }
    }
    case ACTIONS.EDIT_CLIENT_FAILED : {
      return { ...state, error: payload, isLoading: false, success: false }
    }
 
    case ACTIONS.FETCH_CLIENT_INIT : {
      return { ...state, isLoading: true, client: null, error: null }
    }
    case ACTIONS.FETCH_CLIENT_SUCCEDED : {
      return { ...state, client: payload, isLoading: false, error: null }
    }
    case ACTIONS.FETCH_CLIENT_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }

    case ACTIONS.DELETE_CLIENT_INIT : {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.DELETE_CLIENT_SUCCEDED : {
      return { ...state, success: { ...state.success, isDeleted: true }, isLoading: false, error: null }
    }
    case ACTIONS.DELETE_CLIENT_FAILED : {
      return { ...state, isLoading: false, error: payload }
    }

    default: {
      return state
    }
  }
}
