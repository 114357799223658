export const ADMINS = "/admins"
export const ADMIN = "/admins/:param"

export const ADMIN_ACTIVATE = "/admins/:param/activate"
export const ADMIN_DEACTIVATE = "/admins/:param/deactivate"

export const ADMIN_DELETE = "/admins/:param"
export const ADMIN_UNDELETE = "/admins/:param/undelete"

export const ADMINS_ACTIVATE = "/admins/activate"
export const ADMINS_DEACTIVATE = "/admins/deactivate"

export const ADMINS_UNDELETE = "/admins/undelete"
export const ADMINS_DELETE = "/admins/delete"

export const ADMINS_NOTIFICATION = "/api/notifications"
export const READ_NOTIFICATION = "/api/notifications/:param"

export const FCM_TOKEN = "/auth/me/fcm-token"
