import { ACTIONS } from "./../constants"

const initialState = {
  notifications : [],
  totalSize: 0,
  limit: 10,
  page: 1,
  isFetching: false,
  isLoading: false,
  hasMore: true,
  error: null,
}

export default (state = initialState, action) => {
  const { payload, type } = action
  switch (type) {
    case ACTIONS.CLEAR_USER: {
      return {
        ...state,
        success: initialState.success,
        error: null,
        isFetching: false,
        isLoading: false,
      }
    }

    case ACTIONS.USERS_NOTIFICATION_INIT: {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.USERS_NOTIFICATION_SUCCEDED: {
      const { count, page, limit, results } = payload
      return {
        ...state,
        page,
        limit,
        totalSize: count,
        notifications: [ ...state.notifications, ...results],
        isFetching: false,
        error: null,
      }
    }
    case ACTIONS.USERS_NOTIFICATION_FAILED: {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.READ_NOTIFICATION_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.READ_NOTIFICATION_SUCCEDED: {
      return { ...state, isFetching: false, notifications: state.notifications.map((notification) => notification.id !== payload.id ? notification : ({ ...notification, ...payload}))};
    }
    case ACTIONS.READ_NOTIFICATION_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    default: {
      return state
    }
  }
}
