// reclamation
export const FETCH_RECLAMATIONS_INIT = "reclamation@FETCH_RECLAMATIONS_INIT"
export const FETCH_RECLAMATIONS_SUCCEDED = "reclamation@FETCH_RECLAMATIONS_SUCCEDED"
export const FETCH_RECLAMATIONS_FAILED = "reclamation@FETCH_RECLAMATIONS_FAILED"

export const CLEAR_RECLAMATION = "reclamation@CLEAR_RECLAMATION"

export const CREATE_RECLAMATION_INIT = "reclamation@CREATE_RECLAMATION_INIT"
export const CREATE_RECLAMATION_SUCCEDED = "reclamation@CREATE_RECLAMATION_SUCCEDED"
export const CREATE_RECLAMATION_FAILED = "reclamation@CREATE_RECLAMATION_FAILED"

export const FETCH_RECLAMATION_INIT = "reclamation@FETCH_RECLAMATION_INIT"
export const FETCH_RECLAMATION_SUCCEDED = "reclamation@FETCH_RECLAMATION_SUCCEDED"
export const FETCH_RECLAMATION_FAILED = "reclamation@FETCH_RECLAMATION_FAILED"

export const EDIT_RECLAMATION_INIT = "reclamation@EDIT_RECLAMATION_INIT"
export const EDIT_RECLAMATION_SUCCEDED = "reclamation@EDIT_RECLAMATION_SUCCEDED"
export const EDIT_RECLAMATION_FAILED = "reclamation@EDIT_RECLAMATION_FAILED"

export const DELETE_RECLAMATION_INIT = "reclamation@DELETE_RECLAMATION_INIT"
export const DELETE_RECLAMATION_SUCCEDED = "reclamation@DELETE_RECLAMATION_SUCCEDED"
export const DELETE_RECLAMATION_FAILED = "reclamation@DELETE_RECLAMATION_FAILED"
