// client
export const FETCH_CLIENTS_INIT = "client@FETCH_CLIENTS_INIT"
export const FETCH_CLIENTS_SUCCEDED = "client@FETCH_CLIENTS_SUCCEDED"
export const FETCH_CLIENTS_FAILED = "client@FETCH_CLIENTS_FAILED"


export const FETCH_CLIENTSORDER_INIT = "client@FETCH_CLIENTSORDER_INIT"
export const FETCH_CLIENTSORDER_SUCCEDED = "client@FETCH_CLIENTSORDER_SUCCEDED"
export const FETCH_CLIENTSORDER_FAILED = "client@FETCH_CLIENTSORDER_FAILED"

export const FETCH_CLIENTSDEVIS_INIT = "client@FETCH_CLIENTSDEVIS_INIT"
export const FETCH_CLIENTSDEVIS_SUCCEDED = "client@FETCH_CLIENTSDEVIS_SUCCEDED"
export const FETCH_CLIENTSDEVIS_FAILED = "client@FETCH_CLIENTSDEVIS_FAILED"

export const FETCH_CLIENTRETURN_INIT = "client@FETCH_CLIENTRETURN_INIT"
export const FETCH_CLIENTRETURN_SUCCEDED = "client@FETCH_CLIENTRETURN_SUCCEDED"
export const FETCH_CLIENTRETURN_FAILED = "client@FETCH_CLIENTRETURN_FAILED"

export const CLEAR_CLIENT = "client@CLEAR_CLIENT"

export const CREATE_CLIENT_INIT = "client@CREATE_CLIENT_INIT"
export const CREATE_CLIENT_SUCCEDED = "client@CREATE_CLIENT_SUCCEDED"
export const CREATE_CLIENT_FAILED = "client@CREATE_CLIENT_FAILED"

export const FETCH_CLIENT_INIT = "client@FETCH_CLIENT_INIT"
export const FETCH_CLIENT_SUCCEDED = "client@FETCH_CLIENT_SUCCEDED"
export const FETCH_CLIENT_FAILED = "client@FETCH_CLIENT_FAILED"

export const EDIT_CLIENT_INIT = "client@EDIT_CLIENT_INIT"
export const EDIT_CLIENT_SUCCEDED = "client@EDIT_CLIENT_SUCCEDED"
export const EDIT_CLIENT_FAILED = "client@EDIT_CLIENT_FAILED"

export const DELETE_CLIENT_INIT = "client@DELETE_CLIENT_INIT"
export const DELETE_CLIENT_SUCCEDED = "client@DELETE_CLIENT_SUCCEDED"
export const DELETE_CLIENT_FAILED = "client@DELETE_CLIENT_FAILED"
