// structure
export const FETCH_STRUCTURES_INIT = "structure@FETCH_STRUCTURES_INIT"
export const FETCH_STRUCTURES_SUCCEDED = "structure@FETCH_STRUCTURES_SUCCEDED"
export const FETCH_STRUCTURES_FAILED = "structure@FETCH_STRUCTURES_FAILED"

export const CLEAR_STRUCTURE_FORM = "structure@CLEAR_STRUCTURE_FORM"

export const CREATE_STRUCTURE_INIT = "structure@CREATE_STRUCTURE_INIT"
export const CREATE_STRUCTURE_SUCCEDED = "structure@CREATE_STRUCTURE_SUCCEDED"
export const CREATE_STRUCTURE_FAILED = "structure@CREATE_STRUCTURE_FAILED"

export const FETCH_STRUCTURE_INIT = "structure@FETCH_STRUCTURE_INIT"
export const FETCH_STRUCTURE_SUCCEDED = "structure@FETCH_STRUCTURE_SUCCEDED"
export const FETCH_STRUCTURE_FAILED = "structure@FETCH_STRUCTURE_FAILED"

export const EDIT_STRUCTURE_INIT = "structure@EDIT_STRUCTURE_INIT"
export const EDIT_STRUCTURE_SUCCEDED = "structure@EDIT_STRUCTURE_SUCCEDED"
export const EDIT_STRUCTURE_FAILED = "structure@EDIT_STRUCTURE_FAILED"

export const DELETE_STRUCTURE_INIT = "structure@DELETE_STRUCTURE_INIT"
export const DELETE_STRUCTURE_SUCCEDED = "structure@DELETE_STRUCTURE_SUCCEDED"
export const DELETE_STRUCTURE_FAILED = "structure@DELETE_STRUCTURE_FAILED"

// Address
export const FETCH_ADDRESSES_INIT = "address@FETCH_ADDRESSESES_INIT"
export const FETCH_ADDRESSES_SUCCEDED = "address@FETCH_ADDRESSES_SUCCEDED"
export const FETCH_ADDRESSES_FAILED = "address@FETCH_ADDRESSES_FAILED"

export const GET_STATUSFREQUENCY_INIT = "graph@GET_STATUSFREQUENCY_INIT"
export const GET_STATUSFREQUENCY_SUCCEDED = "graph@GET_STATUSFREQUENCY_SUCCEDED"
export const GET_STATUSFREQUENCY_FAILED = "graph@GET_STATUSFREQUENCY_FAILED"



export const GET_STATUSORDERS_INIT = "graph@GET_STATUSORDERS_INIT"
export const GET_STATUSORDERS_SUCCEDED = "graph@GET_STATUSORDERS_SUCCEDED"
export const GET_STATUSORDERS_FAILED = "graph@GET_STATUSORDERS_FAILED"


export const GET_STATUSORDERSVALUE_INIT = "graph@GET_STATUSORDERSVALUE_INIT"
export const GET_STATUSORDERSVALUE_SUCCEDED = "graph@GET_STATUSORDERSVALUE_SUCCEDED"
export const GET_STATUSORDERSVALUE_FAILED = "graph@GET_STATUSORDERSVALUE_FAILED"

export const GET_STATUSORDERSRETURN_INIT = "graph@GET_STATUSORDERSRETURN_INIT"
export const GET_STATUSORDERSRETURN_SUCCEDED = "graph@GET_STATUSORDERSRETURN_SUCCEDED"
export const GET_STATUSORDERSRETURN_FAILED = "graph@GET_STATUSORDERSRETURN_FAILED"

export const GET_STATUSFEEDBACK_INIT = "graph@GET_STATUSFEEDBACK_INIT"
export const GET_STATUSFEEDBACK_SUCCEDED = "graph@GET_STATUSFEEDBACK_SUCCEDED"
export const GET_STATUSFEEDBACK_FAILED = "graph@GET_STATUSFEEDBACK_FAILED"

export const GET_STATUSPANIER_INIT = "graph@GET_STATUSPANIER_INIT"
export const GET_STATUSPANIER_SUCCEDED = "graph@GET_STATUSPANIER_SUCCEDED"
export const GET_STATUSPANIER_FAILED = "graph@GET_STATUSPANIER_FAILED"

export const GET_ORDERREASON_INIT = "graph@GET_ORDERREASON_INIT"
export const GET_ORDERREASON_SUCCEDED = "graph@GET_ORDERREASON_SUCCEDED"
export const GET_ORDERREASON_FAILED = "graph@GET_ORDERREASON_FAILED"

export const GET_FEEDBACKSUBJECT_INIT = "graph@GET_FEEDBACKSUBJECT_INIT"
export const GET_FEEDBACKSUBJECT_SUCCEDED = "graph@GET_FEEDBACKSUBJECT_SUCCEDED"
export const GET_FEEDBACKSUBJECT_FAILED = "graph@GET_FEEDBACKSUBJECT_FAILED"


export const VISITS_INIT = "analytics@VISITS_INIT"
export const VISITS_SUCCEDED = "analytics@VISITS_SUCCEDED"
export const VISITS_FAILED = "analytics@VISITS_FAILED"

export const UNIQUEVISITOR_INIT = "analytics@UNIQUEVISITOR_INIT"
export const UNIQUEVISITOR_SUCCEDED = "analytics@UNIQUEVISITOR_SUCCEDED"
export const UNIQUEVISITOR_FAILED = "analytics@UNIQUEVISITOR_FAILED"

export const CONVERTIONRATE_INIT = "analytics@CONVERTIONRATE_INIT"
export const CONVERTIONRATE_SUCCEDED = "analytics@CONVERTIONRATE_SUCCEDED"
export const CONVERTIONRATE_FAILED = "analytics@CONVERTIONRATE_FAILED"

export const ABONDONEMENT_INIT = "analytics@ABONDONEMENT_INIT"
export const ABONDONEMENT_SUCCEDED = "analytics@ABONDONEMENT_SUCCEDED"
export const ABONDONEMENT_FAILED = "analytics@ABONDONEMENT_FAILED"

export const TOTALVISITS_INIT = "analytics@TOTALVISITS_INIT"
export const TOTALVISITS_SUCCEDED = "analytics@TOTALVISITS_SUCCEDED"
export const TOTALVISITS_FAILED = "analytics@TOTALVISITS_FAILED"






export const CHECKOUTS_INIT = "analytics@CHECKOUTS_INIT"
export const CHECKOUTS_SUCCEDED = "analytics@CHECKOUTS_SUCCEDED"
export const CHECKOUTS_FAILED = "analytics@CHECKOUTS_FAILED"


