// groupClient
export const FETCH_GROUP_CLIENTS_INIT = "groupClient@FETCH_GROUP_CLIENTS_INIT"
export const FETCH_GROUP_CLIENTS_SUCCEDED = "groupClient@FETCH_GROUP_CLIENTS_SUCCEDED"
export const FETCH_GROUP_CLIENTS_FAILED = "groupClient@FETCH_GROUP_CLIENTS_FAILED"

export const CLEAR_GROUP_CLIENT = "groupClient@CLEAR_GROUP_CLIENT"

export const CREATE_GROUP_CLIENT_INIT = "groupClient@CREATE_GROUP_CLIENT_INIT"
export const CREATE_GROUP_CLIENT_SUCCEDED = "groupClient@CREATE_GROUP_CLIENT_SUCCEDED"
export const CREATE_GROUP_CLIENT_FAILED = "groupClient@CREATE_GROUP_CLIENT_FAILED"

export const FETCH_GROUP_CLIENT_INIT = "groupClient@FETCH_GROUP_CLIENT_INIT"
export const FETCH_GROUP_CLIENT_SUCCEDED = "groupClient@FETCH_GROUP_CLIENT_SUCCEDED"
export const FETCH_GROUP_CLIENT_FAILED = "groupClient@FETCH_GROUP_CLIENT_FAILED"

export const EDIT_GROUP_CLIENT_INIT = "groupClient@EDIT_GROUP_CLIENT_INIT"
export const EDIT_GROUP_CLIENT_SUCCEDED = "groupClient@EDIT_GROUP_CLIENT_SUCCEDED"
export const EDIT_GROUP_CLIENT_FAILED = "groupClient@EDIT_GROUP_CLIENT_FAILED"

export const DELETE_GROUP_CLIENT_INIT = "groupClient@DELETE_GROUP_CLIENT_INIT"
export const DELETE_GROUP_CLIENT_SUCCEDED = "groupClient@DELETE_GROUP_CLIENT_SUCCEDED"
export const DELETE_GROUP_CLIENT_FAILED = "groupClient@DELETE_GROUP_CLIENT_FAILED"
