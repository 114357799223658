// TENANT
export const FETCH_TENANTS_INIT = "tenant@FETCH_TENANTS_INIT"
export const FETCH_TENANTS_SUCCEDED = "tenant@FETCH_TENANTS_SUCCEDED"
export const FETCH_TENANTS_FAILED = "tenant@FETCH_TENANTS_FAILED"

export const CLEAR_TENANT = "tenant@CLEAR_TENANT"

export const CREATE_TENANT_INIT = "tenant@CREATE_TENANT_INIT"
export const CREATE_TENANT_SUCCEDED = "tenant@CREATE_TENANT_SUCCEDED"
export const CREATE_TENANT_FAILED = "tenant@CREATE_TENANT_FAILED"

export const FETCH_TENANT_INIT = "tenant@FETCH_TENANT_INIT"
export const FETCH_TENANT_SUCCEDED = "tenant@FETCH_TENANT_SUCCEDED"
export const FETCH_TENANT_FAILED = "tenant@FETCH_TENANT_FAILED"

export const EDIT_TENANT_INIT = "tenant@EDIT_TENANT_INIT"
export const EDIT_TENANT_SUCCEDED = "tenant@EDIT_TENANT_SUCCEDED"
export const EDIT_TENANT_FAILED = "tenant@EDIT_TENANT_FAILED"

export const DELETE_TENANT_INIT = "tenant@DELETE_TENANT_INIT"
export const DELETE_TENANT_SUCCEDED = "tenant@DELETE_TENANT_SUCCEDED"
export const DELETE_TENANT_FAILED = "tenant@DELETE_TENANT_FAILED"
