export const STRUCTURES = "/api/structure"
export const STRUCTURE = "/api/structure/:param"

export const GET_STATUSFREQUENCY = "/stats/customers/frequency"
export const GET_STATUSORDERS = "/stats/orders/figures-volume"
export const GET_STATUSORDERSVALUE = "/stats/orders/figures-value"
export const GET_STATUSORDERSRETURN = "/stats/order-returns/numbers"
export const GET_STATUSFEEDBACK = "/stats/feedbacks/numbers"
export const GET_STATUSPANIER = "/stats/orders/cart-average"
export const GET_ORDERREASON = "/stats/order-returns/reasons"
export const GET_FEEDBACKSUBJECT = "/stats/feedbacks/subjects"

export const VISITS = "/stats/analytics/visits"
export const UNIQUEVISITOR = "/stats/analytics/unique-visitors"
export const CONVERTIONRATE = "/stats/analytics/conversion-rate"
export const ABONDONEMENT = "/stats/analytics/abandonment-rate"
export const TOTALVISITS = "/stats/analytics/total-visits"
