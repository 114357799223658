// quality
export const FETCH_QUALITYS_INIT = "quality@FETCH_QUALITYS_INIT"
export const FETCH_QUALITYS_SUCCEDED = "quality@FETCH_QUALITYS_SUCCEDED"
export const FETCH_QUALITYS_FAILED = "quality@FETCH_QUALITYS_FAILED"

export const CLEAR_QUALITY = "quality@CLEAR_QUALITY"

export const CREATE_QUALITY_INIT = "quality@CREATE_QUALITY_INIT"
export const CREATE_QUALITY_SUCCEDED = "quality@CREATE_QUALITY_SUCCEDED"
export const CREATE_QUALITY_FAILED = "quality@CREATE_QUALITY_FAILED"

export const FETCH_QUALITY_INIT = "quality@FETCH_QUALITY_INIT"
export const FETCH_QUALITY_SUCCEDED = "quality@FETCH_QUALITY_SUCCEDED"
export const FETCH_QUALITY_FAILED = "quality@FETCH_QUALITY_FAILED"

export const EDIT_QUALITY_INIT = "quality@EDIT_QUALITY_INIT"
export const EDIT_QUALITY_SUCCEDED = "quality@EDIT_QUALITY_SUCCEDED"
export const EDIT_QUALITY_FAILED = "quality@EDIT_QUALITY_FAILED"

export const DELETE_QUALITY_INIT = "quality@DELETE_QUALITY_INIT"
export const DELETE_QUALITY_SUCCEDED = "quality@DELETE_QUALITY_SUCCEDED"
export const DELETE_QUALITY_FAILED = "quality@DELETE_QUALITY_FAILED"
