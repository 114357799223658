// discount
export const FETCH_DISCOUNTS_INIT = "discount@FETCH_DISCOUNTS_INIT"
export const FETCH_DISCOUNTS_SUCCEDED = "discount@FETCH_DISCOUNTS_SUCCEDED"
export const FETCH_DISCOUNTS_FAILED = "discount@FETCH_DISCOUNTS_FAILED"

export const CLEAR_DISCOUNT = "discount@CLEAR_DISCOUNT"

export const CREATE_DISCOUNT_INIT = "discount@CREATE_DISCOUNT_INIT"
export const CREATE_DISCOUNT_SUCCEDED = "discount@CREATE_DISCOUNT_SUCCEDED"
export const CREATE_DISCOUNT_FAILED = "discount@CREATE_DISCOUNT_FAILED"

export const FETCH_DISCOUNT_INIT = "discount@FETCH_DISCOUNT_INIT"
export const FETCH_DISCOUNT_SUCCEDED = "discount@FETCH_DISCOUNT_SUCCEDED"
export const FETCH_DISCOUNT_FAILED = "discount@FETCH_DISCOUNT_FAILED"

export const EDIT_DISCOUNT_INIT = "discount@EDIT_DISCOUNT_INIT"
export const EDIT_DISCOUNT_SUCCEDED = "discount@EDIT_DISCOUNT_SUCCEDED"
export const EDIT_DISCOUNT_FAILED = "discount@EDIT_DISCOUNT_FAILED"

export const DELETE_DISCOUNT_INIT = "discount@DELETE_DISCOUNT_INIT"
export const DELETE_DISCOUNT_SUCCEDED = "discount@DELETE_DISCOUNT_SUCCEDED"
export const DELETE_DISCOUNT_FAILED = "discount@DELETE_DISCOUNT_FAILED"
