// ACTIVITY
export const FETCH_ACTIVITIES_INIT = "ACTIVITY@FETCH_ACTIVITIES_INIT"
export const FETCH_ACTIVITIES_SUCCEDED = "ACTIVITY@FETCH_ACTIVITIES_SUCCEDED"
export const FETCH_ACTIVITIES_FAILED = "ACTIVITY@FETCH_ACTIVITIES_FAILED"

export const CLEAR_ACTIVITY = "ACTIVITY@CLEAR_ACTIVITY"

export const CREATE_ACTIVITY_INIT = "ACTIVITY@CREATE_ACTIVITY_INIT"
export const CREATE_ACTIVITY_SUCCEDED = "ACTIVITY@CREATE_ACTIVITY_SUCCEDED"
export const CREATE_ACTIVITY_FAILED = "ACTIVITY@CREATE_ACTIVITY_FAILED"

export const FETCH_ACTIVITY_INIT = "ACTIVITY@FETCH_ACTIVITY_INIT"
export const FETCH_ACTIVITY_SUCCEDED = "ACTIVITY@FETCH_ACTIVITY_SUCCEDED"
export const FETCH_ACTIVITY_FAILED = "ACTIVITY@FETCH_ACTIVITY_FAILED"

export const EDIT_ACTIVITY_INIT = "ACTIVITY@EDIT_ACTIVITY_INIT"
export const EDIT_ACTIVITY_SUCCEDED = "ACTIVITY@EDIT_ACTIVITY_SUCCEDED"
export const EDIT_ACTIVITY_FAILED = "ACTIVITY@EDIT_ACTIVITY_FAILED"

export const DELETE_ACTIVITY_INIT = "ACTIVITY@DELETE_ACTIVITY_INIT"
export const DELETE_ACTIVITY_SUCCEDED = "ACTIVITY@DELETE_ACTIVITY_SUCCEDED"
export const DELETE_ACTIVITY_FAILED = "ACTIVITY@DELETE_ACTIVITY_FAILED"
